* {
  margin: 0;
  padding: 0;
}
 
.app-container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.app-header {
  width: 100%;
}

.app-content {
  width: 100%;
  height: calc(100vh - 118px);
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kfds-c-avatar-payment {
  width: 128px !important;
  height: 128px !important;
}
